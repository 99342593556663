// Generated by Framer (575e68f)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, ResolveLinks, RichText, useComponentViewport, useLocaleInfo, useRouter, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/cq0xvFW9i";
import NavigationDropdownItem from "./awqdPNatU";
const NavigationDropdownItemFonts = getFonts(NavigationDropdownItem);

const cycleOrder = ["shXa7EnC6", "D7WzaCAPW"];

const serializationHash = "framer-rlcKY"

const variantClassNames = {D7WzaCAPW: "framer-v-f7gt4b", shXa7EnC6: "framer-v-w4k5ay"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {delay: 0, duration: 0.15, ease: [0.44, 0, 0.56, 1], type: "tween"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition2}

const addImageAlt = (image, alt) => {
    if (!image || typeof image !== "object") {
        return;
    };
    return { ...image, alt };
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "shXa7EnC6", Tablet: "D7WzaCAPW"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "shXa7EnC6"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "shXa7EnC6", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const router = useRouter()

const isDisplayed = () => {
if (baseVariant === "D7WzaCAPW") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-w4k5ay", className, classNames)} data-border data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"shXa7EnC6"} ref={ref ?? ref1} style={{"--border-bottom-width": "2px", "--border-color": "var(--token-88b875e7-8ca4-4532-b8a8-281609a0f010, rgb(15, 30, 53))", "--border-left-width": "2px", "--border-right-width": "2px", "--border-style": "solid", "--border-top-width": "2px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} variants={{D7WzaCAPW: {"--border-bottom-width": "0px", "--border-left-width": "0px", "--border-right-width": "0px", "--border-top-width": "0px", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}} {...addPropertyOverrides({D7WzaCAPW: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}><ResolveLinks links={[{href: {webPageId: "Y3UKqOvgR"}, implicitPathVariables: undefined}, {href: {webPageId: "Y3UKqOvgR"}, implicitPathVariables: undefined}]}>{(resolvedLinks) => (
<ComponentViewportProvider ><motion.div className={"framer-1w57ltt-container"} layoutDependency={layoutDependency} layoutId={"YnSPlZ4nS-container"} whileHover={animation}><NavigationDropdownItem FaRFuEBtS={addImageAlt({src: "https://framerusercontent.com/images/jZ8igAmsjZt6I4a3HUs0L1UasA.svg"}, "")} height={"100%"} id={"YnSPlZ4nS"} layoutId={"YnSPlZ4nS"} mt997F3WG={"Free"} NBM5Pu6xA={false} ogPfWihuK={"Automation Support"} variant={"GRBjFIWt9"} w6SWDUBJb={resolvedLinks[0]} width={"100%"} {...addPropertyOverrides({D7WzaCAPW: {variant: "mfIqdmvQA", w6SWDUBJb: resolvedLinks[1]}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks><ResolveLinks links={[{href: {webPageId: "aDuwWBHUG"}, implicitPathVariables: undefined}, {href: {webPageId: "aDuwWBHUG"}, implicitPathVariables: undefined}]}>{(resolvedLinks1) => (
<ComponentViewportProvider ><motion.div className={"framer-1rnxpuy-container"} layoutDependency={layoutDependency} layoutId={"Ku2F9Ry0E-container"} whileHover={animation}><NavigationDropdownItem FaRFuEBtS={addImageAlt({src: "https://framerusercontent.com/images/np6j9cnySMG3SCRV1f2TIM41us.svg"}, "")} height={"100%"} id={"Ku2F9Ry0E"} layoutId={"Ku2F9Ry0E"} mt997F3WG={"New"} NBM5Pu6xA={false} ogPfWihuK={"Marketing Tracking"} variant={"GRBjFIWt9"} w6SWDUBJb={resolvedLinks1[0]} width={"100%"} {...addPropertyOverrides({D7WzaCAPW: {variant: "mfIqdmvQA", w6SWDUBJb: resolvedLinks1[1]}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-stvd0u"} data-styles-preset={"cq0xvFW9i"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-afa81745-dff6-439a-9c15-9046c65f1f37, rgb(63, 75, 93)))"}}>Services</motion.p></React.Fragment>} className={"framer-1pdargz"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"IdqYcbzPR"} style={{"--extracted-r6o4lv": "var(--token-afa81745-dff6-439a-9c15-9046c65f1f37, rgb(63, 75, 93))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rlcKY.framer-eonu4r, .framer-rlcKY .framer-eonu4r { display: block; }", ".framer-rlcKY.framer-w4k5ay { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 8px 0px 8px 0px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-rlcKY .framer-1w57ltt-container, .framer-rlcKY .framer-1rnxpuy-container { flex: none; height: auto; position: relative; width: auto; }", ".framer-rlcKY .framer-1pdargz { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rlcKY.framer-w4k5ay { gap: 0px; } .framer-rlcKY.framer-w4k5ay > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-rlcKY.framer-w4k5ay > :first-child { margin-top: 0px; } .framer-rlcKY.framer-w4k5ay > :last-child { margin-bottom: 0px; } }", ".framer-rlcKY.framer-v-f7gt4b .framer-1w57ltt-container { order: 1; }", ".framer-rlcKY.framer-v-f7gt4b .framer-1rnxpuy-container { order: 2; }", ".framer-rlcKY.framer-v-f7gt4b .framer-1pdargz { order: 0; }", ...sharedStyle.css, ".framer-rlcKY[data-border=\"true\"]::after, .framer-rlcKY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 112
 * @framerIntrinsicWidth 210
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"D7WzaCAPW":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZmCRmOTdu: React.ComponentType<Props> = withCSS(Component, css, "framer-rlcKY") as typeof Component;
export default FramerZmCRmOTdu;

FramerZmCRmOTdu.displayName = "Navigation/Dropdown - Services";

FramerZmCRmOTdu.defaultProps = {height: 112, width: 210};

addPropertyControls(FramerZmCRmOTdu, {variant: {options: ["shXa7EnC6", "D7WzaCAPW"], optionTitles: ["Desktop", "Tablet"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerZmCRmOTdu, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...NavigationDropdownItemFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})